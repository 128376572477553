import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { EmailConfirmed } from "./pages/EmailConfirmed/EmailConfirmed";
import { App } from 'antd';
import { ForgotPassword } from "./pages/ForgotPassword/ForgotPassword";
import {ResetPassword} from "./pages/ResetPassword/ResetPassword";
import {ToU} from "./pages/ToU/ToU";
import {GTS} from "./pages/GTS/GTS";
import {HomePage} from "./pages/HomePage/HomePage";
import {NotFound} from "./pages/NotFound/NotFound";
import {ChekerVerification} from "./pages/ChekerVerification/ChekerVerification";
import {ChekerVerificationFinished} from "./pages/ChekerVerificationFinished/ChekerVerificationFinished";

function MyApp() {
  return (
      <App>
          <BrowserRouter>
              <Routes>
                  <Route path="/email-confirmed" element={<EmailConfirmed />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/terms-of-use" element={<ToU />} />
                  <Route path="/general-terms-of-sale" element={<GTS />} />
                  <Route path="/cheker-verification" element={<ChekerVerification />} />
                    <Route path="/cheker-verification-finished" element={<ChekerVerificationFinished />} />
                  <Route path="/" element={<HomePage />} />
                  <Route path="*" element={<NotFound />} />
              </Routes>
          </BrowserRouter>
      </App>
  );
}

export default MyApp;
