import { useTranslation } from "react-i18next";
import './ToU.css';
import Footer from "../../components/Footer/Footer"; // Import the Footer component

export const ToU = () => {
    const { t } = useTranslation();

    const terms = t('ToU.content', { returnObjects: true });

    return (
        <div className="tou-page">
            <div className="tou-container">
                <h1 className="tou-header">{t('ToU.header')}</h1>
                {terms.map((term, index) => (
                    <div key={index} className="tou-term">
                        <h2 className="tou-term-label">{term.label}</h2>
                        <p className="tou-term-desc">{term.desc}</p>
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
}
