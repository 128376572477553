import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Button, Form, Input, message, Alert } from 'antd';
import './ResetPassword.css'; // Import the CSS file
import Footer from "../../components/Footer/Footer"; // Import the Footer component

const onFinish = async (values, code, setLoading, setError, t) => {
    console.log('Success:', values);
    console.log(code);
    setLoading(true); // Set loading state

    try {
        const url = process.env.REACT_APP_API_URL;

        const response = await fetch(`${url}/api/auth/reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code,
                password: values.password,
                passwordConfirmation: values.passwordConfirmation
            })
        });

        const body = await response.json().catch(() => null);

        if (!response.ok) {
            console.error('Error fetching account token:', response.status, body);
            setError(body?.message || 'An error occurred while resetting your password.');
            message.error('Failed to reset password. Please try again.');
        } else {
            message.success('Password reset successfully! You can now log in with your new password.');
        }
    } catch (error) {
        console.error('Error fetching account token:', JSON.stringify(error));
        setError('An unexpected error occurred. Please try again later.');
        message.error('An unexpected error occurred.');
    } finally {
        setLoading(false); // Reset loading state
    }
};

const onFinishFailed = (errorInfo, t) => {
    console.log('Failed:', errorInfo);
    message.error('Please fill in all required fields correctly.');
};

export const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    return (
        <div className="reset-password-page">
            <div className="reset-password-container">
                <h2>{t('resetPassword.header')}</h2>
                {error && <Alert message={error} type="error" showIcon style={{ marginBottom: 20 }} />}
                <Form
                    name="resetPassword"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={(values) => onFinish(values, code, setLoading, setError, t)}
                    onFinishFailed={(errorInf) => onFinishFailed(errorInf, t)}
                    autoComplete="off"
                >
                    <Form.Item
                        label={t('resetPassword.newPassword.label')}
                        name="password"
                        rules={[
                            { required: true, message: t('resetPassword.newPassword.emptyError') },
                            { min: 8, message: t('resetPassword.newPassword.tooShortError') },
                        ]}
                    >
                        <Input.Password placeholder={t('resetPassword.newPassword.placeholder')} />
                    </Form.Item>

                    <Form.Item
                        label={t('resetPassword.confirmPassword.label')}
                        name="passwordConfirmation"
                        dependencies={['password']}
                        rules={[
                            { required: true, message: t('resetPassword.confirmPassword.emptyError') },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('resetPassword.confirmPassword.noMatchError')));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder={t('resetPassword.confirmPassword.placeholder')} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} block>
                            {loading ? t('resetPassword.submitting') : t('resetPassword.submit')}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Footer />
        </div>
    );
};
