import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import './NotFound.css'; // Import the CSS file
import Footer from "../../components/Footer/Footer"; // Import the Footer component

export const NotFound = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleGoHome = () => {
        navigate('/'); // Navigate to the home page
    };

    return (
        <div className="not-found-page">
            <div className="not-found-container">
                <h1 className="not-found-title">404</h1>
                <p className="not-found-message">{t('notFound.message')}</p>
                <Button type="primary" onClick={handleGoHome} className="go-home-button">
                    {t('notFound.goHome')}
                </Button>
            </div>
            <Footer />
        </div>
    );
};
