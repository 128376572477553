import React from 'react';
import { useTranslation } from "react-i18next";
import './Footer.css'; // Import the CSS file for the footer styling

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-links">
                    <a href="/#home" className="footer-link">{t('home.nav.home')}</a>
                    <a href="/#download" className="footer-link">{t('home.nav.download')}</a>
                    <a href="/#about" className="footer-link">{t('home.nav.about')}</a>
                    <a href="/#faq" className="footer-link">{t('home.nav.faq')}</a>
                    <a href="/#contact" className="footer-link">{t('home.nav.contact')}</a>
                </div>
                <div className="footer-terms">
                    <a href="/terms-of-use" className="footer-link">{t('footer.termsOfUse')}</a>
                    <a href="/general-terms-of-sale" className="footer-link">{t('footer.termsOfSale')}</a>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} CARCHEKER. {t('footer.copyright')}</p>
            </div>
        </footer>
    );
};

export default Footer;
