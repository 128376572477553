import React from 'react';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';
import './ChekerVerificationFinished.css';
import Footer from "../../components/Footer/Footer"; // Import the Footer component

export const ChekerVerificationFinished = () => {
    const { t } = useTranslation();

    return (
        <div className="cheker-verification-page">
            <div className="cheker-verification-container">
                <Result
                    status="success"
                    title={t('chekerVerificationFinished.title')}
                    subTitle={t('chekerVerificationFinished.subTitle')}
                />
            </div>
            <Footer />
        </div>
    );
};
