import { Button, Input, Form, Alert } from "antd";
import { useTranslation } from "react-i18next";
import './ForgotPassword.css';
import { useState } from "react";
import Footer from "../../components/Footer/Footer"; // Import the Footer component

export const ForgotPassword = () => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const onFinish = async (values) => {
        setIsLoading(true);
        setErrorMessage(null); // Reset any previous error message

        try {
            const url = process.env.REACT_APP_API_URL;
            const response = await fetch(`${url}/api/auth/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: values.email?.toLowerCase().trim()
                })
            });

            const body = await response.json().catch(() => console.log('Error fetching response'));

            if (!response.ok) {
                console.error('Error fetching account token:', response.status, body);
                setErrorMessage(body?.message || t('forgotPassword.errors.resetLinkNotSent'));
            } else {
                setIsSubmitted(true);
            }
        } catch (error) {
            console.error('Error fetching account token:', JSON.stringify(error));
            setErrorMessage(t('forgotPassword.errors.tryAgainLater'));
        }
        setIsLoading(false);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="forgot-password-page">
            <div className="forgot-password-container">
                <h1 className="forgot-password-header">{t('forgotPassword.header')}</h1>

                {isSubmitted ? (
                    <Alert
                        message={t('forgotPassword.success')}
                        description={t('forgotPassword.successDesc')}
                        type="success"
                        showIcon
                    />
                ) : (
                    <Form
                        name="forgotPasswordForm"
                        layout="vertical"
                        style={{ maxWidth: 600, margin: '0 auto' }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        {errorMessage && (
                            <Alert
                                message={t('forgotPassword.error')}
                                description={errorMessage}
                                type="error"
                                showIcon
                                style={{ marginBottom: 20 }}
                            />
                        )}
                        <Form.Item
                            label={t('forgotPassword.email.label')}
                            name="email"
                            rules={[
                                { required: true, message: t('forgotPassword.email.errorMessage') },
                                { type: 'email', message: t('forgotPassword.email.validEmailRequired') }
                            ]}
                        >
                            <Input style={{ textTransform: 'lowercase' }} placeholder={t('forgotPassword.email.placeholder')} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={isLoading} block>
                                {isLoading ? t('forgotPassword.submitting') : t('forgotPassword.submit')}
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
            <Footer />
        </div>
    );
};
