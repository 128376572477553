import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './translations/en';
import fr from './translations/fr';

i18n
    .use(LanguageDetector) // Use the language detector
    .use(initReactI18next)  // Passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: en,
            },
            fr: {
                translation: fr,
            },
        },
        fallbackLng: 'en', // Fallback language if the detected language is not available
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage', 'cookie'], // Where to store the user's language choice
        },
        interpolation: {
            escapeValue: false, // React already escapes values
        },
        debug: true, // Enable debug mode to see detailed logs in the console
    });

export default i18n;
