import './ChekerVerification.css';
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from 'react';
import { Spin, message } from 'antd';
import Footer from "../../components/Footer/Footer"; // Import the Footer component

export const ChekerVerification = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    // Get id from URL
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    useEffect(() => {
        // Check if id exists in query params
        if (!id) {
            message.error(t('chekerVerif.invalidID'));
            setLoading(false);
            return;
        }

        // Function to fetch redirection URL from backend
        const fetchRedirectUrl = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/cheker/verification-link?id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                // Log the status and response details for debugging
                console.log(`Response Status: ${response.status}`);

                // Ensure the response is successful
                if (!response.ok) {
                    message.error(t('chekerVerif.failedRedirect'));
                    setLoading(false); // If it's not OK, stop loading
                    return;
                }

                // Try to parse the JSON
                const result = await response.json().catch(() => {
                    throw new Error("Failed to parse JSON response");
                });

                // Check if the redirect URL is present in the response
                if (result && result.redirectUrl) {
                    window.location.href = result.redirectUrl; // Perform the redirection
                } else {
                    throw new Error("No redirectUrl found in response");
                }

            } catch (error) {
                console.error('Error fetching the redirection link:', error);
                message.error(t('chekerVerif.errorOccurred'));
                setLoading(false); // Stop the loading spinner when there's an error
            }
        };

        // Call the function
        fetchRedirectUrl();
    }, [id, t]);

    return (
        <div className="cheker-verification-page">
            <div className="redirecting-container">
                {loading ? (
                    <>
                        <Spin size="large" className="loading-spinner" />
                        <p className="redirecting-text">{t('chekerVerif.text')}</p>
                    </>
                ) : (
                    <p className="redirecting-text">{t('chekerVerif.noRedirect')}</p>
                )}
            </div>
            <Footer />
        </div>
    );
};
