import { useTranslation } from "react-i18next";
import './GTS.css';
import Footer from "../../components/Footer/Footer"; // Import the Footer component

export const GTS = () => {
    const { t } = useTranslation();

    const terms = t('GTS.content', { returnObjects: true });

    return (
        <div className="gts-page">
            <div className="gts-container">
                <h1 className="gts-header">{t('GTS.header')}</h1>
                {terms.map((term, index) => (
                    <div key={index} className="gts-term">
                        <h2 className="gts-term-label">{term.label}</h2>
                        <p className="gts-term-desc">{term.desc}</p>
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
}
