import './EmailConfirmed.css';
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined } from '@ant-design/icons';
import Footer from "../../components/Footer/Footer"; // Import an icon from Ant Design

export const EmailConfirmed = () => {
    const { t } = useTranslation();
    return (
        <div className="email-confirmed-page">
            <div className="email-confirmed-container">
                <CheckCircleOutlined className="confirmation-icon" />
                <h1 className="email-confirmed-header">{t('emailConfirmed.header')}</h1>
                <p className="email-confirmed-desc">{t('emailConfirmed.desc')}</p>
                <p className="email-confirmed-instruction">{t('emailConfirmed.gotoApp')}</p>
            </div>
            <Footer />
        </div>
    );
};
